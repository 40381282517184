import { GA4PageNameType, GA4ProductType } from './types'

type BuildGA4ObjectForBXSCheckboxesInput = {
  productName: GA4ProductType
  checkboxProduct: GA4ProductType
  checkboxApplied: boolean
  pageName: GA4PageNameType
}

const buildGA4ObjectForBXSCheckboxes = ({
  productName,
  checkboxProduct,
  checkboxApplied,
  pageName
}: BuildGA4ObjectForBXSCheckboxesInput) => {
  return {
    event: 'select_promotion_bundle',
    attributes: {
      type: 'add_on',
      page_name: pageName,
      product_name: productName,
      checkbox_product: checkboxProduct,
      checkbox_applied: checkboxApplied
    }
  }
}

export default buildGA4ObjectForBXSCheckboxes
