import analytics from '@/shared-utils/analytics'
import { GA4Event, GA4Product } from '@/shared-utils/ga4/types'
import {
  ANALYTICS_CATEGORY_FLIGHTS,
  ANALYTICS_CATEGORY_HOME
} from '@/constants/analytics'
import {
  AbandonedSelection,
  AbandonedSelectionExperiment,
  PackageTypeCode,
  RecentTripSearches,
  SearchProductDataFiltered
} from './types'

function fireGA4EventWithCommonAttributes(
  event: string,
  category?: string,
  additionalAttributes = {}
) {
  const pageName =
    category && category === ANALYTICS_CATEGORY_HOME ? 'homepage' : 'landing'
  analytics.fireGA4Event({
    event,
    attributes: {
      type: 'recent_activity',
      page_name: pageName,
      creative_name: 'pick_up_where_you_left_off',
      ...additionalAttributes
    }
  })
}

const packageTypeCodeMap: { [key in PackageTypeCode]: string } = {
  A: 'a',
  AH: 'ha',
  AC: 'ac',
  AHC: 'hac',
  ACH: 'hac'
}

function mapPackageTypeCode(code: PackageTypeCode): string {
  return packageTypeCodeMap[code]
}

function getLabelFromProductType(productType: string) {
  switch (productType) {
    case 'STAY':
      return 'hotel'
    case 'FLY':
      return 'flight'
    case 'DRIVE':
      return 'car'
    default:
      return ''
  }
}

const getAbandonedSelectionLabel = (
  abandonedSelection: AbandonedSelection | AbandonedSelectionExperiment
) => {
  if (abandonedSelection.productType === 'hotels') {
    switch (abandonedSelection.hotelDealType) {
      case 'RtlHotel':
        return 'hotel'
      case 'SopqHotel':
        return 'hotel express deal'
      case 'AbandonedPriceBreakersHotel':
        return 'hotel pricebreaker'
      case 'RETAIL':
        return 'hotel'
      case 'EXPRESS':
        return 'hotel express deal'
      case 'PRICEBREAKER':
        return 'hotel pricebreaker'
      default:
        return ''
    }
  } else if (abandonedSelection.productType === 'cars') {
    return 'car'
  }
}

export default function fireTripActivityCardClickEvent(
  productType: string,
  category: string
) {
  const label = getLabelFromProductType(productType)
  analytics.fireEvent({
    category,
    action: 'trip activity search click',
    label
  })
  fireGA4EventWithCommonAttributes(GA4Event.CLICK_PROMOTION, category, {
    creative_slot: 'search',
    product_name: label
  })
}

export function fireViewAllButtonClickEvent(category: string) {
  analytics.fireEvent({
    category,
    action: 'hotel selection',
    label: 'view all'
  })
  const pageName =
    category && category === ANALYTICS_CATEGORY_HOME ? 'homepage' : 'landing'
  analytics.fireGA4Event({
    event: GA4Event.DISPLAY,
    attributes: {
      item_name: 'view_all_recent_activity',
      product_name: GA4Product.STAY,
      type: 'link',
      page_name: pageName
    }
  })
  analytics.fireGA4Event({
    event: GA4Event.INTERNAL_LINK,
    attributes: {
      product_name: GA4Product.STAY,
      link_name: 'view_all_recent_activity',
      page_name: pageName
    }
  })
}

export function fireTripActivityLoadEvent(
  category: string,
  searchItems: ReadonlyArray<SearchProductDataFiltered | RecentTripSearches>,
  isLandingPage: boolean
) {
  const ga4Items: Array<{ item_name: string }> = []
  searchItems.forEach(item => {
    const label = getLabelFromProductType(item.productType)
    analytics.fireEvent({
      category,
      action: 'trip activity search load',
      label
    })
    ga4Items.push({ item_name: label })
  })
  fireGA4EventWithCommonAttributes(GA4Event.VIEW_PROMOTION, category, {
    creative_slot: 'search',
    items: ga4Items,
    page_name: isLandingPage ? 'landing' : 'homepage'
  })
}

export function fireAbandonedSelectionLoadEvent(
  category: string,
  abandonedSelection: AbandonedSelection | AbandonedSelectionExperiment,
  isLandingPage: boolean
) {
  const label = getAbandonedSelectionLabel(abandonedSelection)
  analytics.fireEvent({
    category,
    action: 'trip activity selection load',
    label
  })
  fireGA4EventWithCommonAttributes(GA4Event.VIEW_PROMOTION, category, {
    creative_slot: 'selection',
    page_name: isLandingPage ? 'landing' : 'homepage',
    items: [{ item_name: label }]
  })
}

export function fireAbandonedSelectionClickEvent(
  category: string,
  abandonedSelection: AbandonedSelection | AbandonedSelectionExperiment,
  isLandingPage: boolean
) {
  const label = getAbandonedSelectionLabel(abandonedSelection)
  analytics.fireEvent({
    category,
    action: 'trip activity selection click',
    label
  })
  fireGA4EventWithCommonAttributes(GA4Event.CLICK_PROMOTION, category, {
    creative_slot: 'selection',
    page_name: isLandingPage ? 'landing' : 'homepage',
    items: [{ item_name: label }]
  })
}

export function fireFlightsOnContinueClickEvent() {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_FLIGHTS,
    action: 'flight recent search',
    label: 'continue search'
  })
  fireGA4EventWithCommonAttributes(GA4Event.CLICK_PROMOTION, '', {
    creative_slot: 'selection',
    page_name: 'landing',
    product_name: GA4Product.FLY,
    items: [{ item_name: 'air' }]
  })
}

export function fireOnBookABundleCheckboxClick(packageCode: PackageTypeCode) {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_FLIGHTS,
    action: 'flight recent search',
    label: 'checkbox search'
  })
  const mappedPackageCode = mapPackageTypeCode(packageCode)
  fireGA4EventWithCommonAttributes(GA4Event.CLICK_PROMOTION, '', {
    type: 'add_on',
    page_name: 'landing',
    bundle_flag: 'y',
    bundle_type: mappedPackageCode,
    creative_slot: 'selection',
    product_name: GA4Product.FLY
  })
}

export function fireOnCheckboxChangeEvent(name: string) {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_FLIGHTS,
    action: 'flight recent search',
    label: `select ${name}`
  })
}

export function fireFlightRecentSearchViewAllButtonClick() {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_FLIGHTS,
    action: 'flights selection',
    label: 'view all recent activity'
  })
  analytics.fireGA4Event({
    event: GA4Event.DISPLAY,
    attributes: {
      type: 'link',
      product_name: GA4Product.FLY,
      page_name: 'landing',
      item_name: 'view_all_recent_activity'
    }
  })
  analytics.fireGA4Event({
    event: GA4Event.INTERNAL_LINK,
    attributes: {
      product_name: GA4Product.FLY,
      link_name: 'view_all_recent_activity',
      page_name: 'landing'
    }
  })
}
