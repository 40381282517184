import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { LOCATION_SEARCH_TYPE } from '@/types'
import { HOTEL, ONE_WAY, MULTI_DESTINATION, DRIVE } from '../constants'
import { FlightFormStateType } from '../types'

const MAXIMUM_BUNDLE_GUEST_COUNT = 8
const AIRPORT_ERROR = 'Please enter an airport'
const INFANT_CAPACITY_LIMIT = 'Only one infant per adult is allowed'
export const BUNDLE_MAX_CAPACITY_ERROR =
  'Bundles accommodate up to 8 people. You may need an additional bundle for your group'

function handleInfantTravel(this: yup.TestContext) {
  const { adultCount, infantCount } = this.parent

  if (adultCount < infantCount) {
    return this.createError({
      message: INFANT_CAPACITY_LIMIT,
      path: this.path
    })
  }

  return true
}

function handleRoomCapacity(this: yup.TestContext) {
  const { adultCount, childrenCount, tripType, infantCount } = this
    .parent as FlightFormStateType
  const travelerCount = adultCount + childrenCount + infantCount

  if (
    MAXIMUM_BUNDLE_GUEST_COUNT < travelerCount &&
    (tripType.includes(HOTEL) || tripType.includes(DRIVE))
  ) {
    return this.createError({
      message: BUNDLE_MAX_CAPACITY_ERROR,
      path: this.path
    })
  }
  return true
}

const SCHEMA_OW = yup.object().shape({
  endLocation: yup
    .object()
    .transform((val: LOCATION_SEARCH_TYPE) => {
      return isEmpty(val) || val === null ? undefined : val
    })
    .required(AIRPORT_ERROR)
    .test(
      'identical locations',
      "Departure and arrival airport can't be the same",
      function areDistinctLocations(
        this: yup.TestContext,
        val: { id?: string }
      ): boolean {
        const ref: LOCATION_SEARCH_TYPE = this.resolve(yup.ref('startLocation'))

        if (ref?.id === val?.id) {
          return false
        }
        return true
      }
    ),
  startDate: yup.string().required(),
  startLocation: yup
    .object()
    .transform((val: LOCATION_SEARCH_TYPE) => {
      return isEmpty(val) || val === null ? undefined : val
    })
    .required(AIRPORT_ERROR)
})

const SCHEMA_RT = SCHEMA_OW.concat(
  yup.object().shape({
    endDate: yup.string().required()
  })
)

const searchFormSchema = yup.object({
  flightType: yup.string(),
  flights: yup.array().when('flightType', {
    is: (val: string) => val === ONE_WAY || val === MULTI_DESTINATION,
    then: schema => schema.of(SCHEMA_OW),
    otherwise: schema => schema.of(SCHEMA_RT)
  }),
  infantCount: yup.number().test({
    name: 'infantCount',
    test: handleInfantTravel
  }),
  roomCount: yup.number().test({
    name: 'roomCount',
    test: handleRoomCapacity
  })
})

export default searchFormSchema
