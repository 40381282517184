import { GA4PageNameType, GA4Product, GA4Event } from '@/shared-utils/ga4/types'
import analytics from '@/shared-utils/analytics'
import { FlightFormStateType } from './types'
import { GA4TripTypeMapper } from '../BundleAndSave/utils'

export default function fireFlightFormEvent(
  initialState: FlightFormStateType,
  submittedState: FlightFormStateType,
  pageName: GA4PageNameType
) {
  analytics.fireGA4Event({
    event: GA4Event.SEARCH,
    attributes: {
      cabin_class_change:
        initialState.cabinClass !== submittedState.cabinClass ? 'y' : 'n',
      date_change:
        initialState.flights[0].startDate !==
        submittedState.flights[0].startDate
          ? 'y'
          : 'n',
      trip_start_city_change:
        initialState.flights[0].startLocation !==
        submittedState.flights[0].startLocation
          ? 'y'
          : 'n',
      trip_end_city_change:
        initialState.flights[0].endLocation !==
        submittedState.flights[0].endLocation
          ? 'y'
          : 'n',
      quantity_change:
        initialState.adultCount !== submittedState.adultCount ||
        initialState.childrenCount !== submittedState.childrenCount ||
        initialState.infantCount !== submittedState.infantCount ||
        initialState.roomCount !== submittedState.roomCount
          ? 'y'
          : 'n',
      page_name: pageName,
      product_name: GA4Product.FLY
    }
  })
}

export function fireBundleAndSaveEvent(tripType: string) {
  const mappedTripType = GA4TripTypeMapper(tripType)
  analytics.fireGA4Event({
    event: GA4Event.CLICK_PROMOTION,
    attributes: {
      type: 'add_on',
      product_name: GA4Product.FLY,
      bundle_flag: 'y',
      bundle_type: mappedTripType
    }
  })
}

export function fireExpressDealSubmitEvent(pageName: string) {
  analytics.fireGA4Event({
    event: GA4Event.SEARCH,
    attributes: {
      page_name: pageName,
      product_name: GA4Product.FLY
    }
  })
}
