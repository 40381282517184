export const HOTEL = 'STAY'
export const FLY = 'FLY'
export const DRIVE = 'DRIVE'
export const FLY_DRIVE = 'FLY:DRIVE'
export const STAY_FLY = 'STAY:FLY'
export const STAY_FLY_DRIVE = 'STAY:FLY:DRIVE'
export const ONE_WAY = 'one-way'
export const ROUND_TRIP = 'round-trip'
export const MULTI_DESTINATION = 'multi-destination'

export const CABIN_CLASS = {
  Economy: 'ECO',
  'Premium Economy': 'PEC',
  Business: 'BUS',
  First: 'FST'
} as const

export const FLIGHT_TYPE_CODE = {
  [ONE_WAY]: 'OW',
  [ROUND_TRIP]: 'RT',
  [MULTI_DESTINATION]: 'MD'
} as const

export const FLIGHT_TYPE_INFO = {
  [ROUND_TRIP]: {
    flightType: ROUND_TRIP,
    typeLabel: 'Round-trip'
  },
  [ONE_WAY]: {
    flightType: ONE_WAY,
    typeLabel: 'One-way'
  },
  [MULTI_DESTINATION]: {
    flightType: MULTI_DESTINATION,
    typeLabel: 'Multi-destination'
  }
} as const

export const PRIMARY_HEADING_FALLBACK = 'Find your next flight deal'
export const MODIFY_SUBHEADLINE =
  'Update your trip to your preferred flights using the search below.'
