import {
  PACKAGE_SEARCH_DATA,
  persistSearchData
} from '@/shared-utils/local-forage-helper'
import packagesSearch from '@/shared-utils/packages-search'
import queryString from 'query-string'
import { GA4PageNameType } from '@/shared-utils/ga4/types'
import firePackagesFormGA4Event from '../GA4Helpers'
import { MakeWatchObject, PackagesFormStateType } from '../types'
import { FLY_DRIVE, STAY_DRIVE, STAY_FLY, STAY_FLY_DRIVE } from './TripTypes'

export const initialState: PackagesFormStateType = {
  tripType: STAY_FLY,
  partialStay: false,
  startLocation: null,
  endLocation: null,
  startDate: '',
  endDate: '',
  hotelStartDate: '',
  hotelEndDate: '',
  roomCount: 1,
  adultCount: 2,
  childrenCount: 0,
  childrenAges: [],
  isRecommendedTrip: false,
  includeBasicEconomy: true,
  pickupCarSomewhere: false,
  pickupCarIncludeAirport: true,
  rcLocation: null,
  rcPickupDate: '',
  rcDropoffDate: '',
  rcPickupTime: '10:00',
  rcDropoffTime: '10:00',
  gaCategory: ''
}

export const onSubmit = (
  pageName: GA4PageNameType,
  values: PackagesFormStateType
) => {
  const { startLocation, endLocation, childrenAges, ...rest } = values
  const isPartialStayDateValid =
    !!rest.partialStay &&
    (rest.hotelStartDate !== rest.startDate ||
      rest.hotelEndDate !== rest.endDate)

  if (
    startLocation?.type === 'AIRPORT' &&
    startLocation?.lat === 0 &&
    startLocation?.lon === 0
  ) {
    startLocation.type = 'GDS_CITY'
  }
  if (
    endLocation?.type === 'AIRPORT' &&
    endLocation?.lat === 0 &&
    endLocation?.lon === 0
  ) {
    endLocation.type = 'GDS_CITY'
  }

  packagesSearch({
    startLocationObject: startLocation,
    endLocationObject: endLocation,
    childrenAges,
    ...rest,
    isPartialStayDateValid:
      isPartialStayDateValid && rest.tripType !== STAY_DRIVE
  })

  void persistSearchData(PACKAGE_SEARCH_DATA, values)
  firePackagesFormGA4Event(initialState, values, pageName)
}

export const getTextString = (tripType: string) => (text: string) => {
  switch (tripType) {
    case STAY_FLY_DRIVE:
      return `${text}hotel, flight and car`
    case FLY_DRIVE:
      return `${text}flight and car`
    case STAY_DRIVE:
      return `${text}hotel and car`
    case STAY_FLY:
    default:
      return `${text}hotel and flight`
  }
}

export const CALENDAR_POPOVER_SELECTOR =
  '[aria-label="Calendar navigation tooltip"]'

export const fields = [
  'startLocation',
  'endLocation',
  'startDate',
  'endDate',
  'hotelStartDate',
  'hotelEndDate',
  'roomCount',
  'adultCount',
  'childrenCount',
  'childrenAges',
  'tripType',
  'includeBasicEconomy',
  'pickupCarSomewhere',
  'pickupCarIncludeAirport',
  'rcLocation',
  'rcPickupDate',
  'rcDropoffDate',
  'rcPickupTime',
  'rcDropoffTime',
  'gaCategory',
  'isInfantOnInternationalTravel',
  'isChildrenAgeProvided'
] as const

type FormatPackageSearchHref = Pick<
  MakeWatchObject<PackagesFormStateType>,
  | 'watchTripType'
  | 'watchStartLocation'
  | 'watchEndLocation'
  | 'watchStartDate'
  | 'watchEndDate'
  | 'watchRoomCount'
  | 'watchAdultCount'
  | 'watchChildrenCount'
  | 'watchChildrenAges'
  | 'watchHotelStartDate'
  | 'watchHotelEndDate'
  | 'watchPartialStay'
>

export const formatPackageSearchHref = ({
  watchTripType,
  watchStartLocation: startLocation,
  watchEndLocation: endLocation,
  watchStartDate,
  watchEndDate,
  watchRoomCount,
  watchAdultCount,
  watchChildrenCount,
  watchChildrenAges,
  watchHotelStartDate,
  watchHotelEndDate,
  watchPartialStay,
  isUsingAdvancedSearch
}: FormatPackageSearchHref & { isUsingAdvancedSearch: boolean }) => {
  const stringifiedQuery = queryString.stringify({
    'flight-code': isUsingAdvancedSearch ? 'RT' : 'OW',
    'trip-type': watchTripType,
    'origin-item': startLocation?.itemName,
    'origin-id': startLocation?.id,
    'origin-type': startLocation?.type,
    'origin-city': startLocation?.cityName,
    'origin-state': startLocation?.provinceName,
    'origin-state-code': startLocation?.stateCode,
    'origin-country': startLocation?.country,
    'origin-country-code': startLocation?.countryCode,
    'origin-latitude': startLocation?.lat,
    'origin-longitude': startLocation?.lon,
    'destination-item': endLocation?.itemName,
    'destination-id': endLocation?.id,
    'destination-type': endLocation?.type,
    'destination-city': endLocation?.cityName,
    'destination-state': endLocation?.provinceName,
    'destination-state-code': endLocation?.stateCode,
    'destination-country': endLocation?.country,
    'destination-country-code': endLocation?.countryCode,
    'destination-latitude': endLocation?.lat,
    'destination-longitude': endLocation?.lon,
    start: watchStartDate,
    end: watchEndDate,
    rooms: watchRoomCount,
    adults: watchAdultCount,
    children: watchChildrenCount,
    ages: JSON.stringify(watchChildrenAges),
    ...(watchPartialStay
      ? {
          'hotel-start': watchHotelStartDate,
          'hotel-end': watchHotelEndDate
        }
      : {})
  })

  return `/shop/search/trips/?${stringifiedQuery}`
}
