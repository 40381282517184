import { GA4Event, GA4PageNameType, GA4Product } from '@/shared-utils/ga4/types'
import analytics from '@/shared-utils/analytics'
import { PackagesFormStateType } from './types'

export default function firePackagesFormGA4Event(
  initialState: PackagesFormStateType,
  submittedState: PackagesFormStateType,
  pageName: GA4PageNameType
) {
  analytics.fireGA4Event({
    event: GA4Event.SEARCH,
    attributes: {
      cabin_class_change:
        initialState.includeBasicEconomy !== submittedState.includeBasicEconomy
          ? 'y'
          : 'n',
      date_change:
        initialState.startDate !== submittedState.startDate ||
        initialState.endDate !== submittedState.endDate ||
        initialState.hotelStartDate !== submittedState.hotelStartDate ||
        initialState.hotelEndDate !== submittedState.hotelEndDate ||
        initialState.rcPickupDate !== submittedState.rcPickupDate ||
        initialState.rcDropoffDate !== submittedState.rcDropoffDate
          ? 'y'
          : 'n',
      trip_start_city_change:
        initialState.startLocation !== submittedState.startLocation ? 'y' : 'n',
      trip_end_city_change:
        initialState.endLocation !== submittedState.endLocation ? 'y' : 'n',
      quantity_change:
        initialState.adultCount !== submittedState.adultCount ||
        initialState.childrenCount !== submittedState.childrenCount ||
        initialState.roomCount !== submittedState.roomCount
          ? 'y'
          : 'n',
      page_name: pageName,
      product_name: GA4Product.PKG
    }
  })
}

export const trackPackageSearchLinkClick = ({
  isLandingPage = false,
  isUsingAdvancedSearch = false
}) => {
  const pageName = isLandingPage ? 'landing' : 'homepage'

  let ga4Event = {
    event: 'select_promotion',
    attributes: {
      type: 'package',
      page_name: pageName,
      product_name: 'bundle',
      link_name: 'flexible_trip_link'
    }
  }

  if (isUsingAdvancedSearch) {
    ga4Event = {
      event: 'select_promotion',
      attributes: {
        type: 'package',
        page_name: pageName,
        product_name: GA4Product.PKG,
        link_name: 'advanced_search'
      }
    }
  }

  analytics.fireGA4Event(ga4Event)
}
