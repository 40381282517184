import React from 'react'
import { Layout } from 'pcln-design-system'

export default function LayoutComponentWrapper({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <Layout py={[1, null, null, 2]} variation="100" rowGap="xl">
      {children}
    </Layout>
  )
}
